.radio-space {
  margin: 5px;
  border: 1px solid #9dabb1;
  border-radius: 3px;
  width: 25vw;
  padding: 2px;
}

.radio-space.selected {
  border: 1px solid black;
}

.red-error {
  color: red;
  font-weight: bold;
}

.individual-question {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.questionText {
  font-family: "Raleway";
  font-weight: bold;
  font-size: 150%;
  margin-right: 15px;
  margin-bottom: 10px;
}

.question_hint{
  font-size:11px; /*Font Size of Disclaimer Text*/
  font-family:  Arial, Helvetica, sans-serif; /*Web Safe Font Family of Disclaimer Text*/
  background-color: #F7F8FF;
  color: rgb(3, 3, 3);
  text-align: justify;
  padding:15px;
  border-radius:2%;
}

.question-area-parent {
  overflow-y: scroll;
  max-height: 100%;
  width: 50%;
  min-width: 400px;
}

.question-area-parent::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.question-area-parent {
  width: 100%;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.question-form-parent {
  width: 100%;
  max-height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdown-inline {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

/* .control-buttons {
  display: flex;
  flex-direction: row;
  justify-content: end;
} */

.microPhoneButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

/* #f5f5f5 */
.microPhoneButton {
  margin-top: 10px;
  margin-right: 10px;
  width: 20%;
  background-color: rgb(166, 187, 199);
}

.control-button {
  margin: 5px;
  padding: 5px;
  padding-left: 38vw;
  /* align-self: flex-end; */
}

input,
select,
textarea,
label {
  font-size: 20px;
  /* margin-top: 10px; */
  font-family: "Raleway";
}

input[type="radio"] {
  margin: 5px;
}

textarea {
  width: 350px;
  height: 100px;
}

.form-text,
.form-textarea {
  z-index: 1;
  width: 25vw;
  border: 1px solid black;
  padding: 2px;
  border-radius: 3px;
}

select {
  z-index: 1;
  width: 25vw;
  border: 1px solid black;
  padding: 2px;
  border-radius: 3px;
}

.checkbox-flex {
  display: flex;
  flex-direction: row;
  justify-items: space-between;
}

.dropdown-content {
  width: fit-content;
}

.border {
  border: 1px solid black;
  margin-left: 4px;
}

.radio-label-group {
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.input-width {
  width: 350px;
}

.input-margin {
  margin-bottom: 7px;
}

.sub-question {
  margin-left: 40px;
  font-size: 95%;
  color: #3a3a3a;
  margin-bottom: 10px;
}

.blue-button {
  color: #3249B4;
}

.fix-font {
  font-size: 95%;
}

.ques-inactive {
  opacity: 0.7;
}

.ques-active {
  min-height: 75vh;
  width: 100%;

  /* overflow: scroll; */
}

.two-column {
  display: flex;
  flex-direction: row;
}

.disclaimer {
  z-index: 1000;
  position: fixed;
  bottom: 10%;
  left: 2%;
  font-weight: bold;
  font-size: 100%;
  width: 10%;
}

.right-panel {
  z-index: 1000;
  position: fixed;
  bottom: 50%;
  right: 2%;
  font-weight: bold;
  font-size: 120%;
  width: 15%;
  color: #D8000C;
	background-color: #F7F8FF;
}

.formheadingsflex {
  /* display: flex; */
  /* flex-direction: column; */
  min-height: 70vh;
  min-width: 250px;
}

.formheading {
  font-size: 115%;
  margin: 10px 0px 10px 0px;
}

@media screen and (max-width: 970px) {
  .formheadingsflex {
    display: none;
  }
}