/* .container {
  margin: 10px;
  padding: 10px;
} */

.heading {
  display: flex;
  justify-content: space-between;
  font-size: 140%;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.result {
  font-size: 130%;
  font-weight: 100;
}

.report-box {
  /* align-items: center;
  margin: 10px;
  padding: 10px;
  font-size: 100%; */
  border: 0px solid grey;
  border-radius: 3px;
  background-color: #f5f5f5;
  /* width: 75%; */
  border: 1px solid #e1e1e1;
}

.report-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.f1,
.f2 {
  flex-grow: 1;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

th {
  text-align: left;
  background-color: #e1e1e1;
  color: black;
}
