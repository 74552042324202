.claim-page{
    background: #f2ecf4;
    border-radius: 10px;
    text-align: justify;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 25px;
}
@media screen and (max-width: 600px) {
    .claim-page{
        text-align: left;
    }
}