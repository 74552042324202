.brand{
    margin-left: 5%;
}
.login{
    margin-right: 10%;
}
@media screen  and (max-width: 900px){
    .brand{
        margin-left: 2%;
    }
    .login{
        margin-right: 2%;
    }
}