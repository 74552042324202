.NERContainer {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 110%;
  width: 75%;
  margin-left: 10px;
  align-items: center; */
  line-height: 32px;
}

.entity {
  display: flex;
  align-items: center;
  margin: 2px;
  border-radius: 5px;
  padding: 5px;
}

.entity-type {
  margin-left: 5px;
  font-weight: bold;
}

mark {
  margin: 3px;
  background-color: yellowgreen;
}
