.sidebar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 7vh;
  background-color: #FFFFFF;
  font-size: 100%;
  justify-content: center;
  position: fixed;
  align-self: "flex-start";
  position: "sticky";
  top: "10vh";
  z-index: 12;
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.12);
}

#active {
  background-color: #dfdfdf;
}

#passive {
  background-color: #f5f5f5;
  color: grey;
}

.tab-button-container {
  margin: 5px;
  border: 1px solid grey;
}

.tab-button,
.tab-button:focus {
  outline: none;
  width: fit-content;
  height: 100%;
  background: transparent;
  border: 1px solid transparent;
}

.tab-button:active {
  outline: none;
  background: transparent;
  border: 1px solid grey;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.activedot {
  color: #3249B4;
}

.progress-form-heading{
  font-size: 12px;
}
.new-form-heading{
  display: none;
}
@media only screen and (max-width: 1000px) {
  .progress-form-heading{
    font-size: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .progress-form-heading{
    display: none;
  } 
  .new-form-heading{
    display: block;
  }
}
