.queue {
  font-size: 120%;
  margin: 10px;
}

.container-report {
  /* margin: 10px; */
  /* padding: 10px; */
  min-height: 78vh;
}

.login-box {
  margin: 10px;
  padding: 15px;
  border: 0px solid grey;
  border-radius: 3px;
  background-color: #f5f5f5;
  border: 1px solid #e1e1e1;
}

.login-button {
  padding: 4px;
  margin: 7px;
  font-size: 15px;
  background-color: white;
  border: 1px solid black;
}
