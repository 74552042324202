.list-item {
  width: 100%;

  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f9f9f9;
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-title:hover,
.list-icon:hover {
  background-color: #fefefe;
  cursor: pointer;
}

.vertical-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}

.main-title {
  justify-items: baseline;
  width: 100%;
  font-size: 130%;
  align-items: center;
  display: flex;
  height: 100%;
}

.list-icon {
  height: 100%;
  align-content: center;
  margin: 10px;
}

.header {
  width: 75%;
  background-color: #f9f1f1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  justify-content: space-between;
}

.secondary-title {
  width: 25%;
  font-size: 120%;
  align-items: center;
  display: flex;
  height: 100%;
}

.table-heading {
  font-weight: bold;
  font-size: 130%;
}

.fixer {
  margin-right: 40px;
}

.page-header {
  font-size: 200%;
  font-weight: bold;
  width: 75%;
  justify-items: center;
}

.entire-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.list-scroll {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  overflow-y: scroll;
  height: 63vh;
}

.list-scroll::-webkit-scrollbar {
  padding-top: 5px;
  width: 10px;
}

.list-scroll::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.list-scroll::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  border-radius: 100px;
}
