.formBody{
  max-width: 1000px;
}

.formHeading{
  text-align: center;
  font-weight: bold;
}

.microPhoneButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.microPhoneButton {
  margin-top: 10px;
  margin-right: 10px;
  width: 20%;
  background-color: rgb(166, 187, 199);
}

.alert{
  position: fixed;
  top: 10vh;
  left: 50%;
  transform: translate(-50%);
  width: 600px;
  border-radius: 0;
  border: none;
  margin: 0;
  padding: 0 !important;
}

.alert-body{
  max-height: 50vh;
  overflow: auto;
}

.alert-btn{
  padding: 4px 16px;
  background: black;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.alert-label{
  cursor: pointer;
  font-size: 15px;
}

.alert-err{
  font-size: 15px;
}

/* .instructionBar{
  background-color: rgb(244, 204, 244);
} */

.icon{
  margin-top: 10px;
  height: 30px;
  width: 30px;
}

@media screen and (max-width: 700px){
  .alert{
    width: 500px;
  }
}

@media screen and (max-width: 600px){
  .alert{
    width: 400px;
  }
}

@media screen and (max-width: 450px){
  .alert{
    width: 300px;
  }
}