.two-columns {
  display: flex;
  flex-direction: row;
  height: 74vh;
  padding: 15px;
  justify-content: space-between;
}

.editor-subdoc {
  width: 100%;
  margin-top: 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.conditions-subdoc {
  padding: 10px;
  width: 33%;
  height: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  background-color: white;
}

.better-button {
  font-size: 80%;
  margin-right: 10px;
}

.better-select {
  font-size: 80%;
  margin: 10px;
}

.header-input {
  font-size: 180%;
  font-weight: bold;
  width: 40%;
  justify-items: center;
  border: none;
  margin-bottom: 5px;
}

.status-bar {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-left: 20px;
  background-color: #fefefe;
}

.condition-block-main {
  padding: 20px;
  background-color: white;
  border-radius: 15px;
  margin-bottom: 5px;
}
