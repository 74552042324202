.box-signup {
  background-color: rgb(227, 227, 235);
  border-radius: 15px;
}

.box-error {
  background-color: rgb(243, 191, 191);
  border-radius: 15px;
}

.box-success {
  background-color: rgb(141, 236, 142);
  border-radius: 15px;
}

.container-signup {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-signup a {
  text-decoration: none;
}

.form-signup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

.heading-signup {
  font-size: 200%;
  font-weight: bold;
  align-self: center;
}

.login-option {
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;
  background: white;
}

.progressBar{
  position: absolute;
  top: 65px;
}

input.otpInput{
  width: 30px;
}

@media only screen and (max-width: 310px){
  input.otpInput{
    width: 25px;
  }
}

@media only screen and (max-width: 280px){
  input.otpInput{
    width: 22px;
  }
}