.circle{
    border-radius: 50%;
    width: 70px;
    height: 70px;
    padding: 6px;
    background: #FFFFFF;
    border: 1px solid #C59547;
    color: #C59547;
    text-align: center;
    font-family: 'Outfit';
}
.circle-2{
    border-radius: 50%;
    color: #FFFFFF;
    text-align: center;
    font-family: 'Outfit';
    width: 43px;
    height: 43px; 
    font-size: 30px;
    cursor: pointer;
}
.circle-3{
    border-radius: 50%;
    width: 43px;
    height: 43px;
    border: 1px solid #C59547;
    box-sizing: border-box;
    cursor: pointer;
}
.circle-4{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 6px;
}
.sideCard{
    background: #FFFFFF;
    border: 1px solid #2249E2;
    border-radius: 23px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    margin: 0px;
}
.textStyle{
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
}
hr{
    border:none;
    border-bottom: 1px solid #1f1209;
	box-shadow: 0 20px 20px -20px #333;
}
.btn-weiter{
    background: #3249B4;
    border: 0px solid #3249B4;
    border-radius: 12px;
    color: #FFFFFF;
    width: 140px;
    height: 50px;
    font-family: 'Outfit';
    font-weight: 700;
    font-size: 16px;
}
.rule{
    width: 20px;
    background-color: #C59547;
    border: 1.5px solid #C59547;
    transform: rotate(90deg);
}
.heading2{
    width: 65%;
}
.leftHalf{
    padding-left: 50px;
}
@media screen and (max-width: 1000px){
    .rightHalf{
        margin-bottom: 30px;
    }
}
@media screen and (max-width: 300px){
    .heading2{
        width: 85%;
    }
    .item-heading{
        font-size: 12px;
    }
    .leftHalf{
        padding-left: 20px;
    }
    .rightHalf-heading{
        font-size: 15px;
    }
}