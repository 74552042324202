.select {
    width: 87%;
}

button {
    border: solid 2px rgb(144, 144, 144);
    padding: 5px;
    border-radius: 5px;
    background-color: white;
    color: rgb(144, 144, 144);
    font-weight: bold;
    cursor: pointer;
}