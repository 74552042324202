.end-button {
  border: 1px solid black;
  background-color: #f5f5f5;
  margin: 10px;
  font-size: 15px;
  padding: 10px;
}

.questionarea {
  height: 100%;
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.questionaire-container-flex {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.customer-report {
  font-size: 120%;
  margin: 60px;
  width: 45%;
}

.customer-report div {
  margin: 20px;
}

.full-size-center {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-top-bar {
  width: fit-content;
  padding: 5px;
}

.early-end {
  font-size: 26px;
  margin: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-report-box {
  min-height: 70vh;
}

.modal-dialog{
  padding: 0;
  margin: 0;
  max-width: 100%;
}

.alert{
  position: fixed;
  top: 10vh;
  left: 50%;
  transform: translate(-50%);
  width: 600px;
  border-radius: 0;
  border: none;
  margin: 0;
  padding: 0 !important;
}

.alert-body{
  max-height: 50vh;
  overflow: auto;
}

.alert-btn{
  padding: 4px 16px;
  background: black;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.alert-label{
  cursor: pointer;
  font-size: 15px;
}

.alert-err{
  font-size: 15px;
}

@media screen and (max-width: 700px){
  .alert{
    width: 500px;
  }
}

@media screen and (max-width: 600px){
  .alert{
    width: 400px;
  }
}

@media screen and (max-width: 450px){
  .alert{
    width: 300px;
  }
}