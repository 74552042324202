.edit-card {
  margin: 10px;
  padding: 10px;
  border: 1px solid black;
  background-color: aliceblue;
}

input,
button {
  margin: 5px;
}

input[type="select"] {
  font-size: 90%;
}

input[type="text"] {
  width: 600px;
}

.add-element-container {
  min-height: 78vh;
  margin-top: 15px;
}

.resolve-element-card {
  background-color: #eeeeee;
  margin-top: 10px;
  border: 3px solid white;
  padding: 20px;
  border-radius: 5px;
}

.resolve-element-select {
  font-size: 90%;
  margin-left: 5px;
}

.resolve-element-questiontext {
  font-size: 130%;
}
.editform-button {
  border: 1px solid black;
  font-size: 90%;
  margin-left: 20px;
}

.editform-option {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.editform-option-text {
  width: 70%;
}

.change-map {
  border: 2px solid white;
  padding: 10px;
}

.change-map-option {
  margin: 5px;
}

.margin-plz {
  margin-left: 10px;
}

.question-text-input {
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 110%;
  margin: 0px;
  font-weight: bold;
  padding: 0px;
}
.question-name-input {
  padding: 0px;
  background-color: transparent;
  border: none;
  margin: 0px;
}

.editform-option-text {
  padding: 0px;
  background-color: transparent;
  border: none;
  margin: 0px;
}

.resolve-element-text {
  font-size: 90%;
  margin-left: 5px;
  padding: 0px;
  height: fit-content;
  width: fit-content;
}
