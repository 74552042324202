.responses-container {
  margin: 10px;
}

.individual-element {
  padding: 5px;
}

.question {
  font-size: 120%;
  font-weight: bold;
}

.response {
  font-size: 110%;
}
