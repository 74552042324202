.para-block {
  width: 90%;
  margin: 10px;
  padding: 10px;
  border: 1px solid black;
}

.spread {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.para-options {
  width: 50%;
}

.para-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  width: 100%;
}

.bold {
  font-weight: bold;
}

.global-main,
.global-table {
  border: 1px solid black;
  margin: 5px;
  padding: 5px;
}

.main-doc-container {
  min-height: 78vh;
  margin-top: 15px;
}