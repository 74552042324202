.contract{
  position: fixed;
  top: 10vh;
  left: 50%;
  transform: translate(-50%);
  width: 800px;
  border-radius: 0;
  border: none;
  margin: 0;
  padding: 0 !important;
}

.contract-body{
  max-height: 50vh;
  overflow: auto;
}

.contract-btn{
  padding: 4px 16px;
  background: black;
  color: #ffffff;
  border: none;
  cursor: pointer;
}