@font-face {
  font-family: Raleway;
  src: url("./Raleway.ttf");
}

.App {
  font-family: "Raleway";
  /* height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

.stick {
  /* align-self: flex-start; */
  position: fixed;
  bottom: 0;
  z-index: 12;
}

.modal {
  height: fit-content;
  padding: 10;
  margin-top: 50vh;
  margin-right: 25vw;
  border: 1px solid black;
  background-color: white;
}

.landing-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.landing-button {
  display: flex;
  flex-direction: column;
  background-color: #efefef;
  width: 270px;
  height: 120px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 50px;
  justify-content: center;
}

.landing-button-title {
  font-size: 130%;
  margin-bottom: 20px;
}

.landing-step {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding-top: 20px;
  padding-bottom: 50px;
  width: 300px;
  align-items: center;
}

.landing-process {
  display: flex;
  width: 100%;
  justify-content: center;
}

.landing-title {
  width: 100%;
  text-align: center;
  margin: 20px;
  font-size: 250%;
  font-weight: bold;
}

.step-heading {
  font-size: 150%;
  margin-top: 20px;
  font-weight: bold;
}

.landing-center {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.rule {
  border: 1px solid #e1e1e1;
  box-shadow: 0 30px 40px rgba(0, 0, 0, 0.1);
}

.footer {
  align-self: center;
  margin-bottom: 5px;
  margin-top: 50px;
}

.better-font {
  font-family: "Arial";
}

.landing-container {
  min-height: 76vh;
}

.border-table-heading {
  border: 1px solid black;
  margin: 10px;
  padding: 10px;
}

.border-margins-bold {
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 130%;
}