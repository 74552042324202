.questionText {
  font-family: "Raleway";
  font-weight: bold;
  font-size: 150%;
  margin-right: 15px;
  margin-bottom: 10px;
}

.comment-box {
  background-color: grey;
  color: aliceblue;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.timestamp {
  font-size: 10px;
}

@media print {
  .navbar{
    display: none;
  }
  #case-features{
    display: none;
  }
}