body{
    min-height: 100vh;
    position: relative;
}
body::after {
    content: '';
    display: block;
    height: 60px;
}
.footer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0px;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.bar{
    border-left: 1px solid #FFFFFF;
    height: 30px;
    margin: 0px 20px;
}
.foot-link{
    color: #d7d4d4;
}
.foot-link:hover{
    color: #FFFFFF;
}
@media screen and (max-width: 550px){
    .footer{
        flex-direction: column;
    }
}
@media screen and (max-width: 350px){
    .footer{
        font-size: 10px;
        margin: 0;
    }
    .bar{
        margin: 0px 8px;
    }
}
@media print {
.footer{
    display: none;
  }
}