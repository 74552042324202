.workarea {
  padding: 40px;
  margin: 20px;
  background-color: white;
  border-radius: 2px;
  height: fit-content;
  width: 55%;
  /* overflow-y: scroll; */
  box-shadow: 4, 4, 4, 4;
}

.taskbar-button {
  border: none;
  box-shadow: none;
  background-color: transparent;
  font-size: 80%;
  margin: 0px;
  padding: 0px;
}

.editor-components {
  display: flex;
  flex-direction: row;
}

.editor-select {
  font-size: 90%;
  padding: 0px;
  width: fit-content;
}

.condition-dd {
  display: flex;
  flex-direction: row;
  width: 400px;
  justify-content: space-between;
}

.condition-block-main {
  border: 1px solid black;
  margin-top: 10px;
  padding: 5px;
  width: 450px;
}

.mention {
  padding: 0px;
  margin: 0px;
  color: #8b0000;
  vertical-align: baseline;
  display: inline-block;
}

.editor-toolbar {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #efefef;
  padding: 10px;
}

.passive-toolbar-button {
  color: grey;
}

.subdocumentblock {
  border: 1px solid black;
  padding: 5px;
  width: fit-content;
}

.white-behind {
  width: 100%;
  height: 68vh;
  padding: 20px;
  background-color: #f8f9fa;
  overflow-y: scroll;
}

.second-component {
  position: absolute;
  top: 40vh;
  right: 5vw;
  z-index: 10000000000;
}

.spacer {
  width: 50%;
}
