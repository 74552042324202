.Draftail-block--right-align {
  text-align: right;
}

.Draftail-block--right-align > div {
  display: inline-block;
}

hr {
  width: 450px;
}

.space {
  margin: 5px;
}

.white-bg {
  background-color: white;
  width: 95%;
  padding: 5px;
}
